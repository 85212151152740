<template>
  <div class="summary-data">
    <LoadingContainer v-if="loading" />
    <template v-else>
      <a-empty v-if="!data || !data.list.length" />
      <div v-else class="detail">
        <div v-if="payloadSelect" class="filter">
          <a-select
            v-model:value="filter"
            option-filter-prop="key"
            style="min-width: 200px"
            @change="onFilterChanged"
          >
            <a-select-option v-for="o in payloadSelect.options" :key="o.label" :value="o.value">
              {{ o.label }}
            </a-select-option>
          </a-select>
        </div>
        <component
          :is="chartComponent"
          :data="chartData"
          :options="chartOptions"
          :height="chartHeight"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import LoadingContainer from '@/components/LoadingContainer';

export default {
  name: 'DataChart',
  components: {
    LoadingContainer,
    BarChart: defineAsyncComponent(() => import(/* webpackChunkName: "BarChart" */ '@/components/BarChart')),
    PieChart: defineAsyncComponent(() => import(/* webpackChunkName: "BarChart" */ '@/components/PieChart')),
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    loading: {
      type: Boolean,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  emits: ['change'],
  data() {
    return {
      filter: this.config.payloadSelect ? this.config.payloadSelect.default : undefined,
    };
  },
  computed: {
    chartComponent() {
      return this.config.chart || 'BarChart';
    },
    chartData() {
      return this.config.formatter(this.data);
    },
    chartOptions() {
      return {
        maintainAspectRatio: false,
        ...this.config.options,
      };
    },
    chartHeight() {
      return this.config.payloadSelect ? 198 : 250;
    },
    payloadSelect() {
      return this.config.payloadSelect || null;
    },
  },
  methods: {
    onFilterChanged() {
      this.$emit('change', {
        ...this.config.payload,
        [this.payloadSelect.payloadKey]: this.filter,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.summary-data {
  height: 100%;
}

.detail {
  height: 100%;
}

.filter {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
